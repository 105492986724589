<template lang="pug">

Dialog.dialogHistorico(
    header='Histórico Correntista'
    :visible.sync='show'
    @keyup.esc="show = false"
    modal
)
    ProgressBar(v-if='waiting' mode="indeterminate")
    TabView.datatable(style='margin-top:0', @tab-change='onTabChange')
        TabPanel(
            v-for="tab in agendamentosTabs"
            :key="tab.ie_tipo_agenda"
            :header="tab.header"
            v-bind:ieTipoAgenda="tab.ie_tipo_agenda"
            :active="filters.ie_tipo_agenda === tab.ie_tipo_agenda"
            :disabled='waiting'
        )
        DataTable(:value="list" )
            div(v-show='!waiting')
                Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='dt_agenda_f' header='Data')
                Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='hr_agenda_f' header='Horário')
                Column(headerStyle='width: 40%;' header='Procedimento')
                    template(#body='props')
                        div(v-if="props.data.ie_tipo_agenda === 'C'")
                            b {{ props.data.ds_especialidade }}<br>
                            span Dr(a). {{ props.data.ds_especialista }}
                        div(v-else)
                            span(v-if='props.data.nm_procedimento') {{props.data.nm_procedimento}}
                            span(v-else v-for='i in props.data.cd_procedimentos' :key='i.id') &bull; {{ i.nm_procedimento }}
                Column(headerStyle='width: 15%;' header='Estabelecimento')
                    template(#body='props')
                        span {{ props.data.nm_estabelecimento}}
                Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
                    template(#body='props')
                        .cell(:class="{ ex: props.data.ie_status == 'EX', a: props.data.ie_status == 'A', l: props.data.ie_status == 'L' }")
                            span {{ props.data.ie_status_f }}
                Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align:center')
                    template(#body='props')
                        span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                Column(headerStyle='width: 8%;' header='Ações')
                    template(#body='props')
                        .ta-center 
                            Button.p-button-raised.p-button-rounded.mr-1.mb-1(
                                v-tooltip.top="`Visualizar`"
                                icon='pi pi-external-link'
                                @click="goToTransacao(props.data.cd_transacao_financeira)" 
                            )
        div(v-if="!list.length")
            p.ta-center Nenhum item encontrado
    Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style>
.datatable {
    .cell {
        padding: 16px 0;
        text-align: center;
        &.ex  {  background-color: #e4f8e1; }
        &.a  { background-color: #faf3dd; }
        &.l  { background-color: #e1f0f8; }
        &.r  { background-color: #f1e1f8; }
    }
}
.dialogHistorico {
    width: 96%;
    max-width: 1200px;
}
</style>

<script>

import Dialog from 'primevue/dialog'
import Tooltip from 'primevue/tooltip'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { Empresa } from '../../middleware'
import wsConfigs from './../../middleware/configs';
import moment from 'moment'

export default {
    components: {
        Dialog, Paginator, DataTable,
        Column, TabView, TabPanel,
        ProgressBar, ProgressSpinner, Panel,
        Button
    },
    directives: { tooltip: Tooltip },
    created() {
    },
    props: {
        visible: Boolean,
        model: Object
    },
    watch: {
        "model": function (value) {
            this.cd_correntista = value.id
            this.getHistorico()
        },
        "show": function(value) {
            if (!value) {
                this.list = []
                this.filters.ie_tipo_agenda = 'C'
            }
        }
    },
    data() {
        return {
            cd_correntista: null,
            waiting: false,
            list: [],
            paginator: {
                paginacao: true,
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            filters: {
                ie_tipo_agenda: 'C'
            },
            agendamentosTabs: [
                { header: 'Consultas', ie_tipo_agenda: 'C' },
                { header: 'Laboratório', ie_tipo_agenda: 'L' },
                { header: 'Procedimentos', ie_tipo_agenda: 'P' }
            ],
        }
    },
    computed: {
        show: {
            get() { return this.visible },
            set(val) { if (!val) this.$emit('close') }
        }
    },
    methods: {
        onTabChange (ev) {
            this.filters.ie_tipo_agenda = ev.tab.$attrs.ieTipoAgenda
            this.getHistorico()
        },
        goToTransacao(transacao_id) {
            if (transacao_id) {
                this.$router.push(`/transacoes-financeiras-operacional/visualizar/${transacao_id}/`)
            }
        },
        getHistorico() {
            this.waiting = true
            const params = {
                cd_correntista: this.cd_correntista,
                ...this.paginator,
                ...this.filters
            }

            Empresa.historicoAssociado(params).then(response => {
                console.log(response.status)
                if ([200].includes(response.status)) {
                    let ie_status = { 'A': 'Agendado', 'L': 'Livre', 'EX': 'Executado' }
                    this.list = response.data.results
                    console.log(this.list)
                    this.paginator.count = response.data.count
                    this.list.forEach(agendamento => {
                        agendamento.dt_agenda_f = moment(agendamento.dt_agenda).format('DD/MM/YYYY')
                        let aux = agendamento.hr_agenda.split(':')
                        agendamento.hr_agenda_f = `${aux[0]}:${aux[1]}`
                        agendamento.ie_status_f = ie_status[agendamento.ie_status]
                    })
                }
                this.waiting = false
            })
        }
    }
}

</script>